
import React, { useEffect } from "react";
import RemoveIcon from "../../../Assets/icons/remove.svg";
import AddIcon from "../../../Assets/icons/add.svg";
import { products, sizes, walletSizePrize } from "../../../Data/utils";
import { useDispatch, useSelector } from "react-redux";

const Prints = ({ prints, setPrints, fetchedInfo, onPrintValidation }) => {

  var isValid = false;
  const state = useSelector((state) => state.value);
  const updatePrints = (index, key, value) => {

    setPrints((prevFramedPrints) => {
      const updatedFramedPrints = [...prevFramedPrints];
      const productIndex = index; // Assuming `index` is the index of the product to update
      
      if (productIndex >= 0 && productIndex < updatedFramedPrints.length) {
        // isValid = true;
        const productKey = `product${productIndex}`;
        const updatedProduct = {
          ...updatedFramedPrints[productIndex][productKey],
          [key]: value,
        };

        updatedFramedPrints[productIndex] = {
          [productKey]: updatedProduct,
        };
        const hasValidPrint = Object.values(updatedProduct).some(
          (item) => (typeof item === "object" && item.qty > 0) || item === true
        );
        isValid = hasValidPrint;
  
        console.log(isValid);
      }

      onPrintValidation(isValid);
      return updatedFramedPrints;
    });
    
  };

  useEffect(() => {
    const tempArray = [];
    if (fetchedInfo && fetchedInfo.prints.individualSizes.length !== 0) {
      fetchedInfo.prints.individualSizes.forEach((photoDetails, index) => {
        // const packages = fetchedInfo.prints.packages[index];

        var print_package_for_5x7 = 0;
        var print_package_for_8x10 = 0;
        var isWallet = 0;

        if (fetchedInfo.prints.packages && fetchedInfo.prints.packages.length !== 0) {
          fetchedInfo.prints.packages.forEach((printPackage, packageKey) => {

            if (printPackage && printPackage.name === "Print Package (Two 5x7 & One 8x10 each)") {
              print_package_for_5x7 = 2;
              print_package_for_8x10 = 1;
              isValid = true;
            }
            if (printPackage && printPackage.name === "Wallet-Size Prints (Set of 8 each)") {
              isWallet = 1;
              isValid = true;
            }
          });
        }

        let image = '';
        if (photoDetails.photoUrl !== '') {
          image = photoDetails.photoUrl;
        }

        const printSize = {
          [`product${index}`]: {
            size4x6: { qty: 0, min: 0 },
            size5x7: { qty: print_package_for_5x7, min: print_package_for_5x7 },
            size8x10: { qty: print_package_for_8x10, min: print_package_for_8x10 },
            size11x14: { qty: 0, min: 0 },
            size16x20: { qty: 0, min: 0 },
            // isWallet: photoDetails.isWalletSize || 0,
            isWallet: isWallet,
            image: photoDetails.photoUrl || ""
          }
        };

        if (photoDetails.sizes && photoDetails.sizes.length !== 0) {
          photoDetails.sizes.forEach((sizeDetails) => {
            const qty = parseInt(sizeDetails.quantity, 10);
            const sizeKey = `size${sizeDetails.size}`;
            isValid = true;
            if (printSize[`product${index}`][sizeKey]) {
              printSize[`product${index}`][sizeKey].qty += qty;
              printSize[`product${index}`][sizeKey].min += qty;
            }
          });
        }

        tempArray.push(printSize);
      });

      setPrints([...tempArray]);
      // isValid = "true 222";


    } else {
      window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSf_CaHVRmcLF8HR2j8sl6B6YIUs0PG1Z9csWelskqD03JBI5w/viewform?usp=pp_url&entry.671153822=" + state.id;
    }
    // console.log("prints", isValid);
    onPrintValidation(isValid);
  }, []);

  // const updatePrint = (index, key, value) => {
  //   const updatedFramedPrints = prints.map((product, i) => {
  //     if (i === index) {
  //       const keys = Object.keys(product);
  //       const productKey = keys[0];
  //       const productData = product[productKey];

  //       if (value === 0) {
  //         const updatedProductData = { ...productData };
  //         delete updatedProductData[key];

  //         return { [productKey]: updatedProductData };
  //       } else {
  //         return {
  //           [productKey]: { ...productData, [key]: value },
  //         };
  //       }
  //     } else {
  //       return product;
  //     }
  //   });

  //   dispatch(updatePrints(updatedFramedPrints));
  //   setPrints(updatedFramedPrints);
  // };

  return (
    <div>
      <div className="lg:flex justify-between mt-5 lg:mt-8 mb-12">
        <p className="text-lg lg:text-xl font-bold whitespace-nowrap">
          Would you like to add more prints?
        </p>
        <p className="text-base text-[#6B6E76] lg:ml-6">
          Select exactly how many you would like for each photo.
        </p>
      </div>
      <div className="grid grid-cols-7">
        <div className="bg-[#FFEBDD] rounded-tl-xl md:border-b-2 md:border-[#C0BDB7] md:border-opacity-30 border-y md:border-y-0 md:border-l-0 border-l border-[#767676]">
          <div className="">
            <div className=" h-20 flex ml-2 items-center"></div>
          </div>
        </div>
        {sizes.map((item, ind) => {
          const key = Object.keys(item)[0];
          const sizeObject = item[key];
          return (
            <div
              className={`md:border-b-2 pb-3 md:pb-0 pt-3 md:pt-0 md:border-[#C0BDB7] md:border-opacity-30 border-y md:border-y-0 md:border-l-0 border-l border-[#767676] ${ind % 2 === 1 ? "bg-[#EFEFEF] bg-opacity-50" : "bg-white"
                } `}
            >
              <div className="">
                <div className="flex flex-col justify-center pl-2 md:pl-3 pr-2 md:pr-4 h-20 pb-2 text-center">
                  <h1 className="text-[#323640] text-xs md:text-base font-semibold">
                    {sizeObject.size} Print
                  </h1>
                  <p className="text-[#6B6E76] text-[10px] md:text-xs mt-1 md:mt-0">
                    ${sizeObject.price} per photo
                  </p>
                </div>
              </div>
            </div>
          );
        })}
        <div
          className={`md:border-b-2 pb-4 md:pb-0 md:border-[#C0BDB7] md:border-opacity-30 border-y md:border-y-0 md:border-l-0 border-l border-[#767676]  bg-[#EFEFEF] bg-opacity-50 rounded-tr-xl border-r`}
        >
          <div className="">
            <div className="flex flex-col justify-center pl-2 md:pl-3 pr-2 md:pr-4 h-20 pb-2 text-center">
              <h1 className="text-[#323640] text-xs leading-0 md:leading-normal md:text-sm font-semibold">
                Wallet Size
              </h1>
              <p className="text-[#6B6E76] text-[8px] leading-1 md:leading-normal md:text-[11px] mt-1 md:mt-0">
                ${walletSizePrize} per photo
              </p>
            </div>
          </div>
        </div>
      </div>

      {prints.length > 0 &&
        prints.map((item, index) => {
          const productKey = Object.keys(item)[0];
          const product = item[productKey];
          const hasWalletSizePrints = ((fetchedInfo) && fetchedInfo.prints.packages) ? fetchedInfo.prints.packages.some(pkg => pkg.name === "Wallet-Size Prints (Set of 8 each)") : '';

          return (
            <div
              className={`select-none  ${index !== prints.length - 1 &&
                "md:border-b-2 md:border-[#C0BDB7]"
                } md:border-opacity-30  grid grid-cols-7`}
            >
              <div
                className={`${index === prints.length - 1 && "rounded-bl-xl "
                  } bg-[#FFEBDD]  border-b md:border-b-0 border-[#767676] border-l md:border-l-0 `}
              >
                <div className="">
                  <div className="h-[100px] flex ml-2 items-center rounded-lg overflow-hidden">
                    <img
                      className="w-4/5 md:w-auto"
                      src={product.image}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              {sizes.map((size, ind) => {
                const key = Object.keys(size)[0];
                // const value = Number(prints[index][`product${index}`][key].qty);
                // const minValue = prints[index][`product${index}`][key].min;
                const value = prints[index]?.[`product${index}`]?.[key]?.qty !== undefined ? Number(prints[index][`product${index}`][key].qty) : 0;
                const minValue = prints[index]?.[`product${index}`]?.[key]?.qty !== undefined ? Number(prints[index][`product${index}`][key].min) : 0;

                return (
                  <div
                    className={`relative
                       md:border-l-0 border-b md:border-b-0 border-l pt-3 border-[#767676] ${ind % 2 === 1
                        ? "bg-[#EFEFEF] bg-opacity-50"
                        : "bg-white"
                      }
                ${ind === sizes.length - 1 && "  border-r md:border-r-0"}
              
                `}
                  >
                    {minValue > 0 && (
                      <p className="text-[#000] absolute leading-[10px] sm:left-2 md:left-8 font-bold text-center text-[8px] md:text-[10px]">
                        Already paid for {minValue}
                      </p>
                    )}
                    <div className="flex items-center justify-between pl-1 sm:pl-2 pr-1 sm:pr-2 h-20">
                      <div
                        onClick={() => {
                          if (value > 0 && value > minValue) {
                            updatePrints(index, key, {
                              qty: value - 1,
                              min: minValue,
                            });
                          }
                        }}
                        className="w-4 sm:w-7 h-4 sm:h-7 bg-[#DAD6CE] cursor-pointer rounded flex justify-center items-center"
                      >
                        <img
                          className="w-3/5 sm:w-auto "
                          src={RemoveIcon}
                          alt=""
                        />
                      </div>
                      <h1 className="text-[#6B6E76] text-lg font-semibold">
                        {value}
                      </h1>
                      <div
                        onClick={() => {
                          updatePrints(index, key, {
                            qty: value + 1,
                            min: minValue,
                          });
                        }}
                        className="w-4 sm:w-7 h-4 sm:h-7 bg-[#DAD6CE] cursor-pointer rounded flex justify-center items-center"
                      >
                        <img
                          className="w-3/5 sm:w-auto "
                          src={AddIcon}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              <div
                className={` relative rounded-br-xl overflow-hidden
                   md:border-l-0 border-b md:border-b-0 border-l  border-[#767676] bg-[#EFEFEF] bg-opacity-50 bg-white
               border-r md:border-r-0
              
                `}
              >
                {
                  (hasWalletSizePrints) ?
                    <p className="wallet-size text-[#000] absolute leading-[10px] sm:left-2 md:left-8 font-bold text-center text-[8px] md:text-[10px]">
                      Already paid for wallet size
                    </p>
                    : ''
                }
                <div className="flex items-center justify-center pl-1 sm:pl-2 pr-1 sm:pr-4 h-[100px]">
                  <div className="w-4 sm:w-7 h-4 sm:h-7 cursor-pointer rounded flex justify-center items-center">
                    {/* <input
                      onClick={() => {
                        if (!fetchedInfo.isWalletSize) {
                          updatePrints(index, "isWallet", !product.isWallet);
                        }
                      }}
                      checked={product.isWallet}
                      type="radio"
                    /> */}
                    <input
                      onClick={() => {
                        if (
                          !fetchedInfo.isWalletSize &&
                          !hasWalletSizePrints
                        ) {
                          updatePrints(index, "isWallet", !product.isWallet);
                        }
                      }}
                      checked={product.isWallet}
                      type="radio"
                      readOnly={hasWalletSizePrints}
                    />

                  </div>
                </div>
              </div>
            </div>
          );
        })}

    </div>
  );
};

export default Prints;
