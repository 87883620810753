import React, { useEffect, useState } from "react";

const WarningModal = ({ isOpen, onClose }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowModal(true);
    } else {
      setTimeout(() => setShowModal(false), 300);
    }
  }, [isOpen]);

  // Close on "Esc" key press
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") onClose();
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [onClose]);

  if (!showModal) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ${
        isOpen ? "opacity-100" : "opacity-0"
      }`}
      onClick={onClose} // Close on outside click
    >
      <div
        className={`bg-white rounded-2xl shadow-lg p-8 w-100 text-center relative transform transition-all duration-300 ${
          isOpen ? "scale-100 opacity-100" : "scale-90 opacity-0"
        }`}
        onClick={(e) => e.stopPropagation()} // Prevent close when clicking inside
      >
        {/* Warning Icon */}
        <div className="flex justify-center">
          <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center shadow-md">
            <svg
              className="w-12 h-12 text-red-400"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="white" />
              <line x1="12" y1="8" x2="12" y2="14" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
              <line x1="12" y1="16" x2="12" y2="18" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </div>
        </div>

        {/* Modal Message */}
        <h3 className="text-lg font-semibold text-gray-800 mt-4">
            Please select a print size to continue to the next step.
        </h3>

        {/* OK Button */}
        <div className="mt-6">
          <button
            onClick={onClose}
            className="py-2 px-6 bg-orange-400 text-white font-medium rounded-lg shadow-md hover:bg-orange-500 transition"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
